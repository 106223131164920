import axios from './axios';
import routes from "../apiRoutes";

async function api({method, url, data, params, headers}) {
    const sendData = (data instanceof FormData) ? data : JSON.stringify(data);
    const response = await axios({method, url, data: sendData, params, headers})
    return response.data;
}

export class ApiManager {

    async create(route, data, params = {}) {
        return await this.getDataFromRoute(route, 'create', params, data)
    }

    async request(route, params = {}) {
        return await this.getDataFromRoute(route, 'request', params)
    }

    async update(route, data, params = {}) {
        return await this.getDataFromRoute(route, 'update', params, data)
    }

    async delete(route, data, params = {}) {
        return await this.getDataFromRoute(route, 'delete', params, data)
    }

    async getDataFromRoute(route, requestType, bindings, data) {

        if (!(route in routes)) {
            throw new Error(`${route} not found`);
        }

        if ((typeof routes[route]) !== 'function') {
            throw new Error(`route must be function`)
        }

        const {method, url, params, headers} = routes[route](requestType, bindings);

        return await api({method, url, data, params, headers})
    }
}
