import {ButtonGroup, Card, Stack, Table} from "react-bootstrap";
import Dropzone from "../../components/DropZone";
import ApiManager from "../../api";
import {ApiConfirmModalApprovalDialog, ApiPaginator, ApiWrapper} from "../../components/ApiWrapper";
import {truncateString} from "../../utils/helper";
import ButtonEdit from "../../components/buttons/ButtonEdit";
import ButtonDelete from "../../components/buttons/ButtonDelete";
import ApiModalFormWrapper from "../../components/ApiWrapper/ApiModalFormWrapper";
import BoilerplateForm, {validationSchema} from "./BoilerplateForm";
import {useContext} from "react";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";
import {ToastContainer, toast} from 'react-toastify';

function ApprovalText() {
    return <div className="text-center text-danger">
        <h2>Вы точно хотите удалить данный файл?</h2>
        <strong>Удаление файла, может привести к некорректной работе переездов.</strong>
    </div>
}

async function upload(file) {
    let formData = new FormData();

    formData.append("file", file);

    return await new ApiManager().create('boilerplate.upload', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

function Boilerplate() {
    const {setLastFetchedDate} = useContext(ApiWrapperContext)
    return <Stack gap={3}>
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className="d-flex justify-content-between">Загрузка шаблонов</div>
                </Card.Title>
                <Dropzone
                    maxFiles={4}
                    maxSize={1024 * 1024 * 128}
                    multiple={true}
                    accept={{
                        'application/zip': ['.zip']
                    }}
                    onDropAccepted={async function (files) {
                        toast.info("Начата загрузка");

                        for await (const file of files) {
                            await upload(file);
                        }

                        setLastFetchedDate(new Date());
                        toast.info("Файлы загружены");
                    }}
                />
                <p>
                    <div>Максимальный размер файла <strong>128Мб</strong>.</div>
                    <div>Одновременно можно загрузить до <strong>4х</strong> файлов.</div>
                </p>
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className="d-flex justify-content-between">Шаблоны сайтов</div>
                </Card.Title>

                <Table responsive borderless size="sm">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Файл</th>
                        <th>Примечание</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <ApiWrapper>
                        {({fetchedData, handleEdit, handleConfirmDelete}) => fetchedData?.items?.map(item => (
                            <tr key={item.id}>
                                <td>
                                    {item.id}
                                </td>
                                <td>
                                    {truncateString(item.title, 20)}
                                </td>
                                <td>
                                    {truncateString(item?.note ?? '', 100)}
                                </td>
                                <td width="20">
                                    <ButtonGroup className="mb-2">
                                        <ButtonEdit onClick={() => handleEdit(item, 'modalWithForm')}/>
                                        <ButtonDelete onClick={() => handleConfirmDelete(item, 'modalConfirm')}/>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))
                        }
                    </ApiWrapper>
                    </tbody>
                </Table>
                <ApiPaginator/>
                <ApiModalFormWrapper
                    windowId="modalWithForm"
                    validationSchema={validationSchema}
                    title="Редактирование файла"
                >
                    <BoilerplateForm/>
                </ApiModalFormWrapper>
                <ApiConfirmModalApprovalDialog
                    approve={() => <ApprovalText/>}
                    windowId="modalConfirm">
                    <h3 className="text-center">Вы уверены?</h3>
                </ApiConfirmModalApprovalDialog>
                <ToastContainer theme="dark" position="bottom-right"/>
            </Card.Body>
        </Card>
    </Stack>
}

export default Boilerplate
