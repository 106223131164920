import {useDropzone} from "react-dropzone";
import {humanFileSize} from "../../utils/helper";
import {useMemo} from "react";

const errorCodeTranslate = {
    "file-invalid-type": "Неверный тип",
    "file-too-large": "Слишком большой файл",
    "file-too-small": "Слишком маленький файл",
    "too-many-files": "Превышен лимит файлов",
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const dropZoneStyle = {
    width: '100%',
    height: '100px',
    border: '1px',
    borderStyle: 'dashed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem'
}

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function Dropzone(props) {
    const {
        getRootProps,
        getInputProps,
        open,
        fileRejections,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        ...props
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <li key={file.path}>
            <div>{file.path}</div>
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{errorCodeTranslate[e.code] ?? e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {humanFileSize(file.size, true)}
        </li>
    ));

    return (
        <div>
            <div {...getRootProps(style)}>
                <input {...getInputProps()} />
                <div style={dropZoneStyle}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <button type="button" style={{padding: "4px"}} className="btn btn-link" onClick={open}>Выберите файлы</button>
                        <span>или перетащите их сюда</span>
                        {props?.textInfo}
                    </div>
                </div>
            </div>
            {fileRejectionItems.length > 0 ?
                <div className="alert alert-danger">
                    <strong className="alert-heading">Ошибки:</strong>
                    <div>
                        <ul>{fileRejectionItems}</ul>
                    </div>
                </div>
                : ''
            }

            {props?.showAccepted ? <aside>
                <h4>Файлы</h4>
                <ul>{files}</ul>
            </aside> : ''}
        </div>
    );
}

export default Dropzone;
