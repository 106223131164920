import {crudBuilder} from "./api/crudBuilder";

const routes = {
    'cloudflare': crudBuilder({
        pattern: 'profiles/type/cloudflare/profile'
    }),
    'cloudflare.batch-create': crudBuilder({
        pattern: 'profiles/type/cloudflare/profile/batch'
    }),
    'cloudflare.profile-list': crudBuilder({
        pattern: 'profiles/type/cloudflare/profile/list'
    }),
    'cloudflare.group': crudBuilder({
        pattern: 'profiles/type/cloudflare/group'
    }),
    'cloudflare.stat.category': crudBuilder({
        pattern: 'dashboard/cloudflare',
        hasId: false
    }),
    'registrar': crudBuilder({
        pattern: 'profiles/type/registrar/profile'
    }),
    'proxies': crudBuilder({
        pattern: 'proxy'
    }),
    'server': crudBuilder({
        pattern: 'profiles/type/server/profile'
    }),
    'sites.category': crudBuilder({
        pattern: 'sites/category'
    }),
    'sites.category.setting': crudBuilder({
        pattern: 'sites/category/:categoryId/setting'
    }),
    'sites.sites': crudBuilder({
        pattern: 'sites/category/:categoryId/site'
    }),
    'sites.site.history': crudBuilder({
        pattern: 'sites/site/:siteId/transfer-history'
    }),
    'sites.site.rkn-status': crudBuilder({
        pattern: 'sites/site/:siteId/rkn-status'
    }),
    'sites.site.domain-info': crudBuilder({
        pattern: 'sites/site/:siteId/domain-info'
    }),
    'sites.cloudflare.redirect-rule': crudBuilder({
        pattern: 'sites/domain/:domainId/redirect/rule'
    }),
    'sites.cloudflare.dns-record': crudBuilder({
        pattern: 'sites/domain/:domainId/dns/record'
    }),
    'site.domains': crudBuilder({
        pattern: 'sites/domain-list',
        hasId: false
    }),
    'sites.site.amp': crudBuilder({
        pattern: 'sites/category/:categoryId/amp-list',
        hasId: false
    }),
    'sites.test': crudBuilder({
        pattern: 'sites/category/:categoryId/test',
        hasId: false
    }),
    'sites.repeat-transfer': crudBuilder({
        pattern: 'sites/category/:categoryId/repeat-transfer',
        hasId: false
    }),
    'sites.backup-domain': crudBuilder({
        pattern: 'sites/category/:categoryId/backup-domain'
    }),
    'sites.site.glue-domain': crudBuilder({
        pattern: 'sites/site/:siteId/glue-domain'
    }),
    'sites.manual-transfer': crudBuilder({
        pattern: 'sites/site/:siteId/manual-transfer'
    }),
    'sites.waf-group': crudBuilder({
        pattern: 'sites/site/:siteId/waf-group',
    }),
    'stat.category.position': crudBuilder({
        pattern: 'stat/category/:categoryId/position'
    }),
    'stat.domain.position': crudBuilder({
        pattern: 'stat/domain/:domain/position'
    }),
    'stat.category.keyword': crudBuilder({
        pattern: 'stat/category/:categoryId/keyword'
    }),
    'user.list': crudBuilder({
        pattern: 'user/users'
    }),
    'user.maps': crudBuilder({
        pattern: 'user/maps',
        hasId: false
    }),
    'settings': crudBuilder({
        pattern: 'settings',
        hasId: false
    }),
    'randomize-domain': crudBuilder({
        pattern: 'tools/randomize-domain',
        hasId: false
    }),
    'cloudflare.waf.group': crudBuilder({
        pattern: 'waf/group'
    }),
    'cloudflare.waf.rule': crudBuilder({
        pattern: 'waf/group/:groupId/rule'
    }),
    'cloudflare.waf.rule.change-priority': crudBuilder({
        pattern: 'waf/change-priority/:ruleId',
        hasId: false
    }),
    'domain.dns-update': crudBuilder({
        pattern: 'domain/dns-update',
        hasId: false
    }),
    'domain.check-rkn-status': crudBuilder({
        pattern: 'domain/check-rkn-status',
        hasId: false
    }),
    'boilerplate.upload': crudBuilder({
        pattern: 'boilerplate/upload',
        hasId: false
    }),
    'boilerplate': crudBuilder({
        pattern: 'boilerplate'
    }),
    'user-settings': crudBuilder({
        pattern: 'user/settings',
        hasId: false
    }),
    'change-password': crudBuilder({
        pattern: 'change-password',
        hasId: false
    }),
    'notification.telegram': crudBuilder({
        pattern: 'notification'
    }),
    'dashboard.expires-domains': crudBuilder({
        pattern: 'dashboard/expires-domains',
        hasId: false
    }),
    'dashboard.blocked-domains': crudBuilder({
        pattern: 'dashboard/blocked-domains',
        hasId: false
    }),
    'dashboard.pingcheck-domains': crudBuilder({
        pattern: 'dashboard/pingcheck-domains',
        hasId: false
    }),
    'dashboard.launch-check': crudBuilder({
        pattern: 'dashboard/launch-check',
        hasId: false
    })
}

export default routes
