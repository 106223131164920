import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";

import * as Yup from 'yup';
import {useEffect, useState} from "react";
import ApiManager from "../../../api";

export const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Поле обязательно для заполнения.'),
    transferMode: Yup.string().required('Поле обязательно для заполнения.'),
    serverId: Yup.string().required('Поле обязательно для заполнения.'),
    cloudflareId: Yup.string().required('Поле обязательно для заполнения.')
})

export default function SitesListAddForm({serverProfile, cloudflareProfile, siteCategories}) {
    const {values, handleChange, errors, initialValues, setFieldValue} = useFormikContext();
    const [ampList, setAmpList] = useState(null)

    useEffect(() => {
        if (!values.ampCategoryId) {
            return;
        }

        new ApiManager()
            .request('sites.site.amp', {
                paginator: 'without',
                categoryId: values.ampCategoryId
            })
            .then(data => {
                setAmpList(data)
            })
    }, [values.id, values.ampCategoryId])

    return (
        <Form>
            <Form.Group className="mb-3" controlId="email">
                {initialValues['domain'] === '' ? (
                        <>
                            <Form.Label>Домен или домены списком (Каждый домен с новой строки)</Form.Label>
                            <Form.Control
                                name="domain"
                                as="textarea"
                                type="text"
                                value={values.domain}
                                onChange={handleChange}
                                placeholder={`example1.com\nexample2.com\nexample3.com`}
                                isInvalid={!!errors.domain}
                                autoFocus
                            />
                        </>
                    ) :
                    (
                        <>
                            <Form.Label>Домен</Form.Label>
                            <Form.Control
                                name="domain"
                                type="text"
                                value={values.domain}
                                onChange={handleChange}
                                placeholder="Например: example.com"
                                isInvalid={!!errors.domain}
                                autoFocus
                            />
                        </>
                    )}
                <Form.Control.Feedback type="invalid">
                    {errors.domain}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="noteSticky">
                <Form.Label>Примечание (Фиксированное)</Form.Label>
                <Form.Control
                    as="textarea"
                    name="noteSticky"
                    rows={5}
                    value={values.noteSticky}
                    onChange={handleChange}
                    type="text"
                    isInvalid={!!errors.noteSticky}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.noteSticky}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="noteFloat">
                <Form.Label>Примечание (Динамическое)</Form.Label>
                <Form.Control
                    as="textarea"
                    name="noteFloat"
                    rows={5}
                    value={values.noteFloat}
                    onChange={handleChange}
                    type="text"
                    isInvalid={!!errors.noteFloat}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.noteFloat}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="serverId">
                <Form.Label>Профиль сервера</Form.Label>
                <Form.Select
                    name="serverId"
                    value={values.serverId}
                    onChange={handleChange}
                    isInvalid={!!errors.serverId}
                    aria-label="Выберите профиль сервера"
                >
                    <option value="">Выберите профиль Сервера</option>
                    {serverProfile.length > 0 ? serverProfile.map(server => (
                        <option key={server.id} value={server.id}>{server.title} ({server.ipAddress})</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.serverId}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailId">
                <Form.Label>Аккаунт CloudFlare</Form.Label>
                <Form.Select
                    name="cloudflareId"
                    value={values.cloudflareId}
                    onChange={ handleChange }
                    isInvalid={ !!errors.cloudflareId }
                    aria-label="Выберите аккаунт CloudFlare"
                >
                    <option value="">Выберите профиль CloudFlare</option>
                    {cloudflareProfile.profiles.length > 0 ? cloudflareProfile.profiles.map(cloudflare => (
                        <option key={cloudflare.id} value={cloudflare.id}>{cloudflare.title} ({cloudflare.email})  домены: {(typeof cloudflare?.domainsWithCategory !== "undefined") ? cloudflare.domainsWithCategory + '/': ''}{cloudflare.domains}</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    { errors.cloudflareId }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Режим переезда</Form.Label>
                <Form.Select
                    name="transferMode"
                    value={values.transferMode}
                    onChange={handleChange}
                    isInvalid={!!errors.transferMode}
                    aria-label="Режим переезда"
                >
                    <option value="">Выберите режим переезда</option>
                    <option value="manual">Ручной</option>
                    <option value="auto">Автоматический</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.transferMode}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="isAmpSite">
                <Form.Check
                    type="switch"
                    name="isAmpSite"
                    label="АМП дочерний сайт"
                    checked={values.isAmpSite}
                    onChange={(event) => {
                        setFieldValue('isAmpSite', event.target.checked)
                    }}
                    isInvalid={!!errors.isAmpSite}
                />
            </Form.Group>
            {(!values.isAmpSite && initialValues['domain'] !== '') &&
                <>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Категория АМП</Form.Label>
                        <Form.Select
                            name="ampCategoryId"
                            value={values.ampCategoryId}
                            onChange={handleChange}
                            aria-label="Категория АМП"
                        >
                            <option value="">Выберите Категорию АМП</option>
                            {siteCategories.length > 0 ? siteCategories.map(category => (
                                <option key={category.id} value={category.id}>{category.title}</option>
                            )) : ''}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>АМП сайт</Form.Label>
                        <Form.Select
                            name="ampSiteId"
                            value={values.ampSiteId}
                            onChange={handleChange}
                            isInvalid={!!errors.ampSiteId}
                            aria-label="АМП сайт"
                        >
                            <option value="">Выберите АМП сайт</option>
                            {ampList?.length > 0 ? ampList.map(ampSite => (
                                <option key={ampSite.id} value={ampSite.id}>{ampSite.domain} [{ampSite.mainDomain ?? 'не назначен'}]</option>
                            )) : ''}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {errors.ampSiteId}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>
            }

        </Form>
    );
}
