import {Form} from "react-bootstrap";
import {useFormikContext} from "formik";


import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Поле обязательно для заполнения.'),
})

export default function BoilerplateForm() {
    const {values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="file">
                <Form.Label>Название файла</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    isInvalid={ !!errors.title }
                    placeholder="Например: test_boilerplate"
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="note">
                <Form.Label>Примечание</Form.Label>
                <Form.Control
                    as="textarea"
                    name="note"
                    rows={5}
                    value={ values.note }
                    onChange={ handleChange }
                    type="text"
                    isInvalid={ !!errors.note }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.note }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}


